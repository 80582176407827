import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import HowToModal from '../components/HowToModal/HowToModal';
import Reward from '../components/Reward';
import { addStag } from '../utils/utils';
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import WheelViolet from "../components/Wheel/WheelViolet";
import FooterViolet from "../components/FooterVariantA/FooterViolet";
import LogoCenterViolet from "../components/LogoCenter/LogoCenterViolet";
import players from "../images/players-violet.png"

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  html, body {
    margin: 0;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
  }
  body {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    background: #250028;
    color: #838FA3;
    overflow-x: hidden;
    min-width: 100%;
    min-height: 100%;
  }
  
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #8C28C9;
  }

  ::-webkit-scrollbar-track {
    background: #250028;
  }
  
  img {
    user-drag: none;
    user-select: none;
  }
  
  .no-scroll {
    overflow-y: hidden;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const WheelContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const WheelWrapper = styled.div`
  width: auto;
  overflow: hidden;
  position: absolute;
  top: -110px;
  right: 0;
  z-index: 200;

  @media screen and (max-width: 1250px) {
    right: -120px;
  }
  @media screen and (max-width: 968px) {
    position: relative;
    top: 0;
    margin: -40px auto 0 auto;
    right: auto;
  }
  @media screen and (max-width: 650px) {
    margin: -20px auto 0 auto;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0 0 0;
    position: absolute;
    left: -20vw;
    width: 140vw;
    height: 140vw;
    margin-top: -120px;
    
    > div {
      width: 140vw;
      height: 140vw;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: -100px;
  }
`;

const SpinAndWin = styled.div`
  background: #fff;
  font-size: 36px;
  font-weight: 500;
  padding: 8px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  color: #320636;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  @media screen and (max-width: 650px) {
    font-size: 28px;
  }
  
  span {
    margin: 0 24px 0 0;
  }
`;

const PlayersWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  height: 612px;
  width: 100%;

  @media screen and (max-width: 968px) {
    height: auto;
  }
`;

const PlayersWrapperInner = styled.div`
  max-width: 1400px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;

  @media screen and (max-width: 968px) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 600px) {
    padding-top: 140vw;
  }
  @media screen and (max-width: 500px) {
    padding-top: 160vw;
  }
`;

const PlayersImg = styled.img`
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 50;
  width: 601px;
  height: auto;
  
  @media screen and (max-width: 1250px) {
    left: -120px;
  }
  @media screen and (max-width: 968px) {
    position: relative;
    left: 0;
    top: 100px;
    margin: -140px auto 0;
  }
  @media screen and (max-width: 968px) {
    margin-top: -200px;
  }
  @media screen and (max-width: 600px) {
    max-width: 100vw;
  }
  @media screen and (max-width: 500px) {
    top: 0;
  }
`;

const ExtraBg = styled.div`
  background: rgba(104, 1, 114, 0.6);
  height: 58px;
  position: absolute;
  bottom: 120px;
  left: 0;
  width: 100%;
  z-index: 100;
`;

const ExtraBg2 = styled.div`
  background: #6A0074;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 150;
`;

const LPViolet = () => {
  const [win, setWin] = useState(null);
  const [bonusInfo, setBonusInfo] = useState(false);

  const promoUrl = "";

  const pageContext = {
    "copyright": "© 2021 All Rights Reserved. Sportbet.one",
    "modalFailButtonText": "SPIN AGAIN",
    "modalFailMessage": "Luck isn't on your side",
    "modalFailSubtitle": "Not this time",
    "modalSuccessAverageMessage": "$10 Risk-Free Bet!",
    "modalSuccessButtonText": "GRAB NOW",
    "modalSuccessLowestMessage": "$5 Risk-Free Bet!",
    "modalSuccessMaxMessage": "$50 Risk-Free Bet!",
    "modalSuccessRetryButtonText": "SPIN AGAIN",
    "modalSuccessSubtitle": "You won:",
    "modalSuccessTitle": "CONGRATULATIONS!",
    "section1ButtonText": "GET STARTED",
    "section1Description": "Create your account instantly.",
    "section2Description": "Add crypto to your Sportbet.one wallet.",
    "section1Title": "SIGN UP",
    "section2Title": "DEPOSIT",
    "section3ButtonText": "Place bet",
    "section3Description": "Switch on “Place Risk-Free bet” and get up to $50 back if you lose",
    "section3Title": "BET",
    "sectionsButtonText": "Grab now",
    "sectionsTitle": "How to get a bonus?",
    "showTermsAndConditions": true,
    "slug": "whl-kenya-risk-free50-en",
    "subTitle": "Completely anonymous sports and casino betting!",
    "title": "Spin the weel and win a bonus!",
    "footerText": {
      "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"New customers only Risk - Free Bet is for sports betting only and will be added to your wallet immediately after registration. Free bet is valid for 7 days. No cashout or wagering restrictions. Terms & Conditions apply. Please bet responsibly, begambleaware.org\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
    "termsApplyButtonText": "APPLY",
    "termsAndConditionsTitle": "Terms & Conditions",
    "termsAndConditions": {
      "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Risk-Free Bet amount will be refunded in case of loss.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Offer is valid for new players only.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Offer is limited to one per person, family, IP address, device, email address, or billing information. In case we suspect a breach of the rules (use of fake accounts, groups of players, etc.), SportBet reserves the right to cancel the Risk-Free Bet up to $50 offer and all the winnings within it.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- After participation in the Risk-Free Bet up to $50 promo, you will have 7 days to use your Risk-Free Bet.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Sportbet.one reserves the right to withhold or cancel any Risk-Free Bets and winnings in cases where the Risk-Free Bet has been mistakenly accrued, or in cases where the abuse by the player has been identified.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
    "wheelAverageBonusText": "Risk-Free Bet!",
    "wheelAverageBonusTitle": "$10",
    "wheelLowestBonusText": "Risk-Free Bet!",
    "wheelAverageBonusUrl": "https://sportbet.one/?promo=60bfeeade92e6249eede9032",
    "wheelLowestBonusTitle": "$5",
    "wheelLowestBonusUrl": "https://sportbet.one/?promo=60bfeeade92e6249eede9032",
    "wheelMaxBonusText": "Risk-Free Bet!",
    "wheelMaxBonusTitle": "$50",
    "wheelMaxBonusUrl": "https://sportbet.one/?promo=5ffc5844da053d1b47cf4d74",
    "wheelSpinText": "SPIN",
    "wheelTryAgainText": "TRY AGAIN",
  };

  useEffect(() => {
    if (typeof document === "undefined") return;
    const linkClick = (e) => {
      const href = e.currentTarget.href;
      if (href.includes("promo=")) {
        e.preventDefault();
        window.location.href = addStag(href);
      }
    };

    document.querySelectorAll("a").forEach(x => x.addEventListener("click", linkClick));
    return () => {
      document.querySelectorAll("a").forEach(x => x.removeEventListener("click", linkClick));
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Sportbet.one</title>
      </Helmet>
      <GlobalStyle />
      {!bonusInfo && win ? <Reward pageContext={pageContext} win={win} onClose={() => { setWin(null); }} openBonus={() => setBonusInfo(true)} isViolet={true} /> : null}
      {bonusInfo && win ? <HowToModal win={win} onClose={() => { setBonusInfo(false); }} {...pageContext} /> : null}
      <Container>
        <LogoCenterViolet promoUrl={promoUrl} />
        <WheelContainer>
          <PlayersWrapper>
            <SpinAndWin>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
              <span>Spin and win!</span>
            </SpinAndWin>
            <PlayersWrapperInner>
              <PlayersImg src={players} />
              {/*Players here*/}
              <WheelWrapper>
                <WheelViolet setWin={setWin} {...pageContext} />
              </WheelWrapper>
            </PlayersWrapperInner>
            <ExtraBg />
            <ExtraBg2 />
          </PlayersWrapper>
        </WheelContainer>
        <FooterViolet />
      </Container>
    </>
  );
};

export default LPViolet;
