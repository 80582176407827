import React, { useState } from 'react';
import TermsModal from '../TermsModal';
import styled from "styled-components";

const Footer = styled.div`
  text-align: center;
  width: 100%;
  padding: 85px 0;
  background: linear-gradient(180deg, #6A0074 0%, #320636 100%);
`;

const FooterInner = styled.div`
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 12px;

  @media screen and (max-width: 599px) {
    flex-direction: column-reverse;
    align-items: center;

    .footer__age {
      margin-bottom: 12px;
    }
  }
`;

const FooterText = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin: 0 0 12px 0;
  padding: 0 12px;

  @media screen and (max-width: 599px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const TermsContainer = styled.p`
  a {
    color: #fff;
    text-decoration: underline;
    margin-bottom: 12px;
    font-size: 18px;
    display: block;

    @media screen and (max-width: 599px) {
      font-size: 12px;
    }
  }
`;

const Age = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  line-height: 17px;
  color: #72017C;
  font-size: 14px;
  
  @media screen and (max-width: 599px) {
    margin-bottom: 12px;
  }
`;

const FooterViolet = () => {
  const [termsShown, setTermsShown] = useState(false);

  return (
    <>
      {termsShown ? (
        <TermsModal
          termsAndConditionsTitle={'TERMS & CONDITIONS'}
          termsAndConditions={{raw: "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Risk-Free Bet amount will be refunded in case of loss.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Offer is valid for new players only.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Offer is limited to one per person, family, IP address, device, email address, or billing information. In case we suspect a breach of the rules (use of fake accounts, groups of players, etc.), SportBet reserves the right to cancel the Risk-Free Bet up to $50 offer and all the winnings within it.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- After participation in the Risk-Free Bet up to $50 promo, you will have 7 days to use your Risk-Free Bet.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Sportbet.one reserves the right to withhold or cancel any Risk-Free Bets and winnings in cases where the Risk-Free Bet has been mistakenly accrued, or in cases where the abuse by the player has been identified.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"}}
          termsApplyButtonText={'Apply'}
          onClose={() => setTermsShown(false)}
        />
      ) : null}
      <Footer>
        <FooterInner>
          <FooterWrapper>
            <Age>18+</Age>
            <FooterText>New customers only Risk - Free Bet is for sports betting only and will be added to your wallet immediately after registration. No cashout or wagering restrictions. Please bet responsibly, begambleaware.org</FooterText>
          </FooterWrapper>
          <TermsContainer>
            <a href="#" role={"button"} onClick={e => {
              e.preventDefault();
              setTermsShown(true);
            }}>Terms & Conditions</a>
          </TermsContainer>
          <FooterText>
            © All Rights Reserved. Sportbet.one
          </FooterText>
        </FooterInner>
      </Footer>
    </>
  );
};

export default FooterViolet;
